import * as React from 'react'
import { useState } from "react"
import { Link, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Slider from "react-slick";
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss";

import FooterLayout from '../components/footerLayout'
import roseTheo from '../assets/img/rose_theo.png'
import transition from '../assets/img/transition.png'

import titre from '../assets/img/logo.png'
import actu from '../assets/img/default.jpg'

const IndexPage = ({ data }) => {
  const [isOpen, setOpen] = useState(false);
  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return ( 
    <body>
      <header className="main_header">
        <title> Les aventures de Rose </title>
        <div className="header_content">
          <img src={roseTheo} id="rose_theo"/>
          <div className="blocTexte">
            <img src={titre} alt="Les aventures de Rose" />
            <MDXRenderer>{ data.header.body }</MDXRenderer>
            { typeof window != 'undefined' && ModalVideo && 
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="hgmO3SxQbrg"
                onClose={() => setOpen(false)}/> 
            }
            <a className="blue_button js-modal-btn" onClick={() => setOpen(true)}> Découvrir </a>
          </div>
        </div>
      </header>

      <header class="header_mobile">
        <img src={roseTheo}/>
      </header>

      <div className="boutonFix">
        <a className="button_vers_site_institutionnel js-modal-btn" href="https://jpeuxpasjaimusee.org/" target="_blank" rel="noreferrer"><p>Retourner sur le site Jpeuxpasjaimusee.org</p></a>
      </div> 

      <section className="presentation_mobile">
        <img src={titre} className="titre"/>
        <img src={transition} className="transition" />
        <p> <MDXRenderer>{ data.header.body }</MDXRenderer> </p>
      </section>

      <section className="intro">
        <div className="">
          <StaticImage className="polaroid polaroid1" src="../assets/img/polaroid3.png" />
          <div> <MDXRenderer>{ data.intro.body }</MDXRenderer> </div>
          <StaticImage className="polaroid polaroid3" src="../assets/img/polaroid1.png" />
          <StaticImage className="polaroid polaroid4" src="../assets/img/polaroid2.png" />
          <div className="map"><StaticImage className="map_img" src="../assets/img/map.png" /></div>
        </div>
      </section>

      <section className="intro_mobile">
        <span className="intro_mobile_content">
          <div> <MDXRenderer>{ data.intro.body }</MDXRenderer> </div>
        </span>
      </section>
      

      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
     {/*
      <section className="museums">
        <div className="wrap">
          <MDXRenderer>{ data.museums.body }</MDXRenderer>
          <div className="museums-container">
            <a className="item">
              <Link to="/espaceAngouleme"><StaticImage src="../assets/img/polaroid-angouleme.png" /></Link>
              <Link to="/espaceAngouleme"><h4>Angoulême</h4></Link>
            </a>
            <a className="item">
              <Link to="/espaceLaRochelle"><StaticImage src="../assets/img/polaroid-la-rochelle.png" /></Link>
              <Link to="/espaceLaRochelle"><h4>La Rochelle</h4></Link>
            </a>
            <a className="item">
              <Link to="/"><h4>Rediriger ver la site JPPJM</h4></Link>
            </a>
          </div>
        </div>
      </section> 
       */}
      <section className="news">
        <h2>Les actualités</h2>
        { data.actualites.totalCount === 0 && <h3> Aucune actualité publiée pour le moment... </h3>}
        <div className="wrap">
          <Slider {...sliderSettings}>
            { data.actualites.nodes.map(node => (
              <div className="news-item item">
                <div>
                  <div className="texteNews">
                    <h3> { node.title } </h3>
                    <p> { node.description } </p>
                  </div>
                </div>
                <img src={actu} alt="Image actualité"/>
              </div>
            )) }
          </Slider>
        </div>
      </section>
      <FooterLayout></FooterLayout>
    </body>  
  );
}

export const query = graphql`
  query {
    header: mdx(slug: { eq: "header" }) {
      body
    }
    intro: mdx(slug: { eq: "intro" }) {
      body
    }
    museums: mdx(slug: { eq: "museums" }) {
      body
    }
    actualites: allActualites {
      totalCount
      nodes {
        title
        description
        image
      }
    }
  }
`

export default IndexPage
